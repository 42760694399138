import React from "react"

import Header from "@ecom/ui/components/Header/Halva"

const phoneProps = {
  phones: ["8 (800) 100-77-72"],
}

interface IEmptyHeader {
  onLogoClick?: () => void
}

export default function EmptyHeader({ onLogoClick }: IEmptyHeader) {
  return <Header PhoneProps={phoneProps} onLogoClick={onLogoClick} />
}
