import React, { useEffect } from "react"

import Container from "@ecom/ui/components/Container"
import Header from "../components/Headers/EmptyHeader"
import Layout from "../components/Layouts/secondaryPage"
import Footer from "../components/Footer"
import { DLErrorEvent } from "../helpers/WatcherDL/DLEvents"

export default function NotFoundPage() {
  const redirectToHomePage = () => {
    window.open("https://gethalva.ru/", "_blank")
  }

  useEffect(() => {
    DLErrorEvent()
  }, [])

  return (
    <Layout>
      <Header onLogoClick={redirectToHomePage} />
      <Container style={{ flex: "1 0 auto" }}>
        <h1 style={{ textAlign: "center", fontSize: 30, fontWeight: 500, margin: "50px 0" }}>
          Страница не найдена
        </h1>
      </Container>
      <Footer shortText="" />
    </Layout>
  )
}
